import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    routes:[
        // Fleetan 轨迹查询官网
        {
            path:'/',
						name:'/',
            component: () => import("@/views/Index.vue")
        },
				// Fleetan 轨迹查询官网
        {
            path:'/index',
						name:'index',
            component: () => import("@/views/Index.vue")
        },
    ]
})
export default router;