import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import axios from 'axios'
import ElementUi from 'element-ui'
import { Message,MessageBox } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible/flexible.js'

import Mint from 'mint-ui'
import 'mint-ui/lib/style.css'

Vue.config.productionTip = false

Vue.use(ElementUi)
Vue.use(VueRouter)
Vue.use(Mint)

// 重置信息提示功能
import { message } from '@/components/reset/resetMessage';

axios.defaults.baseURL = 'https://www.dlvd.com'

// axios.defaults.baseURL = 'http://bbs.fleetan.com'

// axios.defaults.baseURL = 'http://172.18.0.34:7003'

axios.defaults.timeout = 90000

Vue.prototype.$http = axios
Vue.prototype.$message = Message

new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
