<template>
	<div id="app">
		<transition :name="transitionName">
			<keep-alive>
				<router-view class="routerView" />
			</keep-alive>
		</transition>
	</div>
</template>

<script>
	export default {
		name: "App",
		data() {
			return {
				transitionName: ''
			}
		},
		watch: {
			// 监听路由对象，决定使用哪种过渡效果
			'$route'(to, from) {
				// 获取到携带的标记
				const routerType = to.query.routerType
				console.log(routerType);
				if (routerType === 'push') {
					// 跳转页面
					this.transitionName = 'slide-left'
				} else {
					// 后退页面
					this.transitionName = ''
				}
			}
		},
		components: {}
	};
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		width: 100%;
		height: 100%;
		position: relative;
	}
	.routerView {
		position: absolute;
	}
	.slide-right-enter-active,
	  .slide-right-leave-active,
	  .slide-left-enter-active,
	  .slide-left-leave-active {
	    will-change: transform;
	    transition: all .6s;
	    position: absolute;
	  }
	  .slide-right-enter {
	    /* opacity: 0; */
			
	    transform: translate3d(-100%, 0, 0);
	  }
	  .slide-right-leave-active {
	    /* opacity: 0; */
	    transform: translate3d(100%, 0, 0);
	  }
	  .slide-left-enter {
	    /* opacity: 0; */
	    transform: translate3d(100%, 0, 0);
	  }
	  .slide-left-leave-active {
	    /* opacity: 0; */
	    transform: translate3d(-100%, 0, 0);
	  }
</style>
